import My360Store from 'app/stores/My360Store';

import AlignModel from './AlignModel';

export interface My360Participant {
  id: number;
  name: string;
  email: string;
  status: string;
  isSelected: boolean;
  inviteLink?: string;
}

export class My360Model extends AlignModel {
  static _store: My360Store;
}

export default My360Model;
