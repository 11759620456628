import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import ContractStore from 'app/stores/ContractStore';

export enum ContractType {
  MSA = 'msa',
  BUNDLE = 'bundle',
  AUTO = 'auto',
}
export class ContractModel extends Model {
  static _store: ContractStore;

  @observable id: number;
  @observable organization_id: number;
  @observable type: ContractType;
  @observable label: string;
  @observable licenses: number;
  @observable used: number;
  @observable start_date: string;
  @observable end_date: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as ContractModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as ContractModel;
  }

  static get(id) {
    return this._get(id) as ContractModel;
  }
}

export default ContractModel;
