import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import FeatureStore from 'app/stores/FeatureStore';

export enum Features {
  OrgReportUseNewDivisionRollup = 'OrgReportUseNewDivisionRollup',
  TeamOnboardingTeamGroups = 'TeamOnboardingTeamGroups',
  HideAlignPreview = 'HideAlignPreview',
  UsePerspectiveAsFirstSuggestedStep = 'UsePerspectiveAsFirstSuggestedStep',
  PairPerspectiveConvoGuide = 'PairPerspectiveConvoGuide',
  PairPerspectivev2 = 'PairPerspectivev2',
  TeamPerspectiveDisabled = 'TeamPerspectiveDisabled',
  MarketingHomepageTabDisabled = 'MarketingHomepageTabDisabled',
  PaipPerspectiveMemberRating = 'PaipPerspectiveMemberRating',
  ShowPulseTeamHabits = 'ShowPulseTeamHabits',
  PerspectiveUnderStressEnabled = 'PerspectiveUnderStressEnabled',
  TeamAlignEmptyStateMarketing = 'TeamAlignEmptyStateMarketing',
  TeamPerspectiveEmptyStateMarketing = 'TeamPerspectiveEmptyStateMarketing',
  PerspectiveCalendar = 'PerspectiveCalendar',
  PostPerspectiveQuizEnabled = 'PostPerspectiveQuizEnabled',
  OrgUsageDashboardDisabled = 'OrgUsageDashboardDisabled',
  NewHomePageEnabled = 'NewHomePageEnabled',
  ContractActivitiesTracking = 'ContractActivitiesTracking',
  AiCoachDashboardIntegrationEnabled = 'AiCoachDashboardIntegrationEnabled',
  DisableBillingHoneypotElements = 'DisableBillingHoneypotElements',
}

type FeatureStatus = 'off' | 'whitelist' | 'on' | 'custom';

export class FeatureModel extends Model {
  public static _store: FeatureStore;

  @observable id: number;
  @observable name: string;
  @observable status: FeatureStatus;
  @observable custom?: any;
  @observable created_at: string;
  @observable updated_at: string;
  @observable enabled: boolean;

  static fromJson(json: ModelJson): FeatureModel {
    return this._fromJson(json) as FeatureModel;
  }

  static getOrNew(id: number): FeatureModel {
    return this._getOrNew(id) as FeatureModel;
  }

  static get(id: number): FeatureModel {
    return this._get(id) as FeatureModel;
  }
}

export default FeatureModel;
