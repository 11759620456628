import React, { ReactNode } from 'react';

import { Skeleton } from 'antd';
import { ModalHeader } from 'reactstrap';

export interface HeaderProps {
  title?: ReactNode | string;
  onToggle?: () => void;
  onBack?: () => void;
  dataTrackPrefix?: string;
  closeButtonTag?: string;
  loading?: boolean;
}

export const Header: React.StatelessComponent<HeaderProps> = ({
  title,
  onToggle,
  onBack,
  dataTrackPrefix,
  closeButtonTag,
  loading,
}) => {
  const closeButton = onToggle && (
    <button
      type="button"
      className="modal-close-btn"
      onClick={onToggle}
      data-testid="modal-close-btn"
      data-track={dataTrackPrefix && `${dataTrackPrefix}-close-btn`}
      data-tag={closeButtonTag}
    >
      <i className="fas fa-xmark fa-sm" />
    </button>
  );

  return (
    <ModalHeader close={closeButton}>
      {onBack && (
        <button type="button" className="modal-back-btn" onClick={onBack}>
          <i className="fas fa-chevron-left fa-sm" />
          <span>Back</span>
        </button>
      )}

      {title && (
        <Skeleton loading={loading} title={false} paragraph={{ rows: 2 }}>
          <span>{title}</span>
        </Skeleton>
      )}
    </ModalHeader>
  );
};

export default Header;
