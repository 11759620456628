import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { matchPath } from 'react-router';
import { RouteComponentProps, Switch } from 'react-router-dom';

import AppLayout from 'app/components/features/AppLayout';
import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';
import { useBodyClass } from 'app/helpers/hooks';

const AiCoach = lazy(() => import('./AiCoach'));
const Home = lazy(() => import('./Home'));
const NewTeam = lazy(() => import('./Team/NewTeam'));
const TeamDashboard = lazy(() => import('./Team'));
const TeamsEmptyProductPage = lazy(() => import('./Team/TeamsEmptyProductPage'));
const UserProfileMenu = lazy(() => import('./UserProfileMenu'));
const Organization = lazy(() => import('./Organization'));
const Resources = lazy(() => import('./Resources'));
const ManageMy360 = lazy(() => import('./Myself/My360/ManageMy360'));
const SelfAssessment = lazy(() => import('./CreateMy360/SelfAssessment'));
const InviteParticipants = lazy(() => import('./CreateMy360/InviteParticipants'));
const SelectMy360Template = lazy(() => import('./CreateMy360/SelectMy360Template'));
const Myself = lazy(() => import('./Myself'));
const MyColleagues = lazy(() => import('./MyColleagues'));
const Coach = lazy(() => import('./Coach'));
const Perspective = lazy(() => import('./Perspective'));

// Skip rendering if these are the paths since they are
// external and doesn't actually need topnav.
//
// We should have fought for this early on to be on different url, oh well.
const excludePathInfos = [
  { path: '/dashboard/referrals', exact: true, strict: false },
  { path: '/dashboard/referrals/email', exact: true, strict: false },
  { path: '/teams/:teamId/pulses/:token', exact: true, strict: false },
  {
    path: '/dashboard/teams/:teamId/perspective/manager-playbook/:memberId?',
    exact: true,
    strict: false,
  },
  {
    path: '/dashboard/teams/:team_id/team-games/connect/:team_game_id?/:step/:memberId?',
    exact: true,
    strict: false,
  },
];

const DashboardRoutes: React.FC<RouteComponentProps> = (props) => {
  // Set background class
  useBodyClass('with-gray-bg');

  const { match, location } = props;

  const skip = excludePathInfos.find((pathInfo) => matchPath(location.pathname, pathInfo));
  if (skip) {
    return null;
  }

  return (
    <AppLayout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path={`${match.path}/ai_coach`} component={AiCoach} />
          <Route path={`${match.path}/my_teams`} component={Home} />
          <Route path={`${match.path}/teams/new`} exact component={NewTeam} />
          <Route path={`${match.path}/teams/new/:orgId`} exact component={NewTeam} />

          <Route
            path={`${match.path}/teams/perspective/:subpage?`}
            component={TeamsEmptyProductPage}
          />
          <Route path={`${match.path}/teams/connect/:subpage?`} component={TeamsEmptyProductPage} />
          <Route path={`${match.path}/teams/align`} component={TeamsEmptyProductPage} />
          <Route path={`${match.path}/teams/teamHabits`} component={TeamsEmptyProductPage} />

          <Route path={`${match.path}/home`} component={TeamDashboard} />
          <Route path={`${match.path}/teams/:teamId`} component={TeamDashboard} />

          <Route path={`${match.path}/profile/:tab/:organizationId?`} component={UserProfileMenu} />
          <Route path={`${match.path}/organizations`} component={Organization} />
          <Route path={`${match.path}/resources`} component={Resources} />
          <Route path={`${match.path}/my_colleagues`} component={MyColleagues} />
          <Route path={`${match.path}/coach`} component={Coach} />
          <Route exact path={`${match.path}/perspective/:organizationId`} component={Perspective} />

          <Route
            exact
            path={`${match.path}/my_360/:exerciseId/manage/:tab?`}
            component={ManageMy360}
          />
          <Route
            exact
            path={`${match.path}/my_360/:exerciseId/self_assessment`}
            component={SelfAssessment}
          />

          <Route
            exact
            path={`${match.path}/my_360/:exerciseId/invite_participants`}
            component={InviteParticipants}
          />

          <Route
            exact
            path={`${match.path}/my_360/new/:memberId?`}
            component={SelectMy360Template}
          />

          <Route path={match.path} component={Myself} />
        </Switch>
      </Suspense>
    </AppLayout>
  );
};

export default <Route path="/dashboard" component={DashboardRoutes} />;
