import React, { ReactNode } from 'react';

import { ArrowLeftOutlined, CheckOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { Card, Col, Row } from 'antd';
import cx from 'classnames';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import numeral from 'numeral';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import './PulseReport.scss';

import AppHeader from 'app/components/features/AppHeader';
import MemberLicenseBanner from 'app/components/features/MemberLicenseBanner';
import BarChart from 'app/components/ui/Charts/BarChart/BarChart';
import LineChart from 'app/components/ui/Charts/LineChart/LineChart';
import FakeBox from 'app/components/ui/FakeBox';
import { CreateHabitsIcon } from 'app/components/ui/icons';
import Logo from 'app/components/ui/Logo';
import {
  PulseStatementOptionsNames,
  PulseStatementType,
  STORE_PERSONAL_PULSE_REPORT,
  STORE_PULSE_FOR_MEMBER,
  STORE_PULSE_FOR_TEAM,
  SURVEY_DOT_COLOR_BLACK,
  SURVEY_DOT_COLOR_GRAY,
  SURVEY_DOT_COLOR_GREEN,
  SURVEY_DOT_COLOR_GREEN_DARK,
  SURVEY_DOT_COLOR_GREEN_LIGHT,
  SURVEY_DOT_COLOR_ORANGE_DARK,
  SURVEY_DOT_COLOR_ORANGE_LIGHT,
  SURVEY_DOT_COLOR_RED,
} from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { ResponseStatement, ResponseValue } from 'app/models';
import { PulseForMemberStore, PulseForTeamStore, PulseReportStore } from 'app/stores';

import PulseReportContext from './PulseReportContext';
import PulseReportUIStore from './PulseReportUIStore';

const BINARY_TYPE_PADDING = 0.8;
const OWNER_TYPE_TEAMS = 'teams';

export interface RouteParams {
  ownerId: string;
  ownerType: string;
  pulseId: string;
}

export interface PulseReportProps extends RouteComponentProps<RouteParams> {
  ownerId: number;
  pulseForMemberStore?: PulseForMemberStore;
  pulseForTeamStore?: PulseForTeamStore;
  personalPulseReportStore?: PulseReportStore;
  ownerType?: string;
  pulseToken?: string;
  isTestDrive?: boolean;
}

export class PulseReport extends React.Component<PulseReportProps> {
  uiStore: PulseReportUIStore;

  @observable activeDateIndex = -1;
  @action setActiveDateIndex = (activeDateIndex) => (this.activeDateIndex = activeDateIndex);

  constructor(props: PulseReportProps) {
    super(props);
    this.init();
  }

  private init = (): void => {
    this.uiStore = new PulseReportUIStore({
      ownerId: this.ownerId,
      ownerType: this.ownerType,
      pulseId: this.pulseId,
      pulseForMemberStore: this.props.pulseForMemberStore,
      pulseForTeamStore: this.props.pulseForTeamStore,
      personalPulseReportStore: this.props.personalPulseReportStore,
      pulseToken: this.props.pulseToken,
    });
  };

  get isLoading(): boolean {
    return this.uiStore.isLoading;
  }

  get scaleColors() {
    return {
      '-3': SURVEY_DOT_COLOR_RED,
      '-2': SURVEY_DOT_COLOR_ORANGE_DARK,
      '-1': SURVEY_DOT_COLOR_ORANGE_LIGHT,
      '0': SURVEY_DOT_COLOR_GRAY,
      '1': SURVEY_DOT_COLOR_GREEN_LIGHT,
      '2': SURVEY_DOT_COLOR_GREEN_DARK,
      '3': SURVEY_DOT_COLOR_GREEN,
      'N/A': SURVEY_DOT_COLOR_BLACK,
    };
  }

  get binaryColors() {
    return {
      '0': SURVEY_DOT_COLOR_RED,
      '1': SURVEY_DOT_COLOR_GREEN,
      'N/A': SURVEY_DOT_COLOR_BLACK,
    };
  }

  @computed
  get ownerId() {
    return numeral(this.props.match.params.ownerId).value();
  }

  @computed
  get ownerType() {
    if (this.props.ownerType) {
      return this.props.ownerType;
    }

    return this.props.match.params.ownerType;
  }

  @computed
  get heading() {
    return this.ownerType === OWNER_TYPE_TEAMS ? 'Team Habits' : 'Personal Habits';
  }

  @computed
  get responseRates(): ReactNode {
    if (!this.uiStore.reportData) {
      return null;
    }

    return (
      <Row className="personal-habit-report-response-rates">
        <Col span={8}>Response Rates</Col>
        <Col span={16}>
          <ParentSize>
            {({ width, height }) => (
              <BarChart
                key={'d1'}
                width={width}
                height={height}
                padding={null}
                hideZero={false}
                showLabel
                showGradient
                data={this.uiStore.responseRatesBarData}
              />
            )}
          </ParentSize>
        </Col>
      </Row>
    );
  }

  @computed
  get pulseId() {
    return numeral(this.props.match.params.pulseId).value();
  }

  @computed
  get dashboadUrl(): string {
    if (this.ownerType === 'teams') {
      return ServerRouteHelper.dashboard.teamPage(this.ownerId, 'teamHabits');
    }

    return ServerRouteHelper.dashboard.myself.myHabits();
  }

  @computed
  get backButton(): ReactNode {
    return (
      <Link to={this.dashboadUrl} className="back-button">
        <ArrowLeftOutlined className="left-icon" />
        Return to dashboard
      </Link>
    );
  }

  @computed
  get headerNav(): ReactNode {
    if (this.props.isTestDrive) {
      return null;
    }

    const dateStarted = this.uiStore.pulse?.started_at?.format('MMM DD, YYYY');

    return (
      <>
        {this.memberLicenseBanner}
        <AppHeader
          className="personal-habit-report-header"
          hideLogo
          forceLayout
          leftComponent={this.backButton}
          centerComponent={
            <div className="logo-container">
              <Logo />
              <div className="pulse-info">
                <h2>Habit Report</h2>
                <div className="date-info">{dateStarted}</div>
              </div>
            </div>
          }
        />
      </>
    );
  }

  @computed
  get bodyHeader(): ReactNode {
    return (
      <h1>
        <CreateHabitsIcon />
        {this.heading}
      </h1>
    );
  }

  rendertile = (index: number, label: string): ReactNode => {
    const isSelected = this.activeDateIndex === index;
    return (
      <div
        className={cx('nav-item', {
          active: isSelected,
        })}
        onClick={() => {
          this.setActiveDateIndex(index);
        }}
        key={`nav-item-${index}`}
      >
        <div
          className={cx('nav-tile-radio', {
            checked: isSelected,
          })}
        >
          {isSelected && <CheckOutlined />}
        </div>
        &nbsp;&nbsp;{label}
      </div>
    );
  };

  @computed
  get datesNavigation(): ReactNode {
    return (
      <Col span={6}>
        <p>Navigate through different dates:</p>
        {this.rendertile(-1, 'Overview')}
        {this.uiStore?.responseDates.map((date, i) => {
          return this.rendertile(i, moment(date).format('MMMM Do'));
        })}
      </Col>
    );
  }

  graphData = (responseValues, statementType: string) => {
    const graphData = [];

    responseValues?.forEach((response) => {
      const data = {
        label: response.value,
        value: response.count,
        color: this.getColor(statementType, response.value),
      };
      graphData.push(data);
    });

    return graphData;
  };

  getColor(statementType: string, responseValue: number) {
    if (statementType === PulseStatementType.Scale) {
      return this.scaleColors[responseValue];
    }

    if (statementType === PulseStatementType.Binary) {
      return this.binaryColors[responseValue];
    }
  }

  statementTextResponses = (responseValues: ResponseValue[]): ReactNode => {
    const responses = responseValues.filter(({ metric_type }) => 'text');

    return (
      <div className="statement-text-container">
        {responses.map((response, i) => (
          <p className="response-text" key={i}>
            {response.value}
          </p>
        ))}
      </div>
    );
  };

  renderStatementPerType = (
    statement: ResponseStatement,
    responseValues: ResponseValue[],
    maxCount: number
  ): ReactNode => {
    if (!responseValues) {
      return (
        <p className="response-text empty-response">
          <ExclamationCircleFilled /> No entries so far.
        </p>
      );
    }

    if (
      statement.statement_type === PulseStatementType.Scale ||
      statement.statement_type === PulseStatementType.Binary
    ) {
      const barPadding =
        statement.statement_type === PulseStatementType.Binary ? BINARY_TYPE_PADDING : null;

      return (
        <ParentSize>
          {({ width, height }) => (
            <BarChart
              key={'d1'}
              width={width}
              height={height}
              padding={barPadding}
              hideZero={false}
              maxValue={maxCount}
              data={this.graphData(responseValues, statement.statement_type)}
            />
          )}
        </ParentSize>
      );
    }

    if (statement.statement_type === PulseStatementType.Text) {
      return this.statementTextResponses(responseValues);
    }
  };

  renderStatement = (statement: ResponseStatement, index: number): ReactNode => {
    // This needs to be here so it can be observed
    const activeDateIndex = this.activeDateIndex;

    const activityDate = this.uiStore.responseDates[activeDateIndex];
    const maxCount = this.uiStore.getMaxResponsesByActivity(activityDate);

    const responseValues = this.uiStore.getResponsesByActivityAndStatement(
      activityDate,
      statement.id as number
    );

    return (
      <Row key={index} className="personal-habit-report-statements">
        <Col span={8} className="habit-statement">
          {statement.text}
          <p className="habit-type">
            Habit type: {PulseStatementOptionsNames[statement.statement_type]}
          </p>
        </Col>
        <Col span={16} className="habit-statement-display">
          {this.renderStatementPerType(statement, responseValues, maxCount)}
        </Col>
      </Row>
    );
  };

  renderOverviewStatement = (statement: ResponseStatement, index: number): ReactNode => {
    const graphData = this.uiStore.getOverallGraphByStatement(statement.id as number);
    const glyphData = this.uiStore.getGlyphData();

    if (statement.statement_type === PulseStatementType.Text) {
      return null;
    }

    return (
      <Row key={index} className="personal-habit-report-statements">
        <Col span={8}>{statement.text}</Col>
        <Col span={16}>
          <ParentSize>
            {({ width }) => <LineChart data={graphData} glyphData={glyphData} width={width} />}
          </ParentSize>
        </Col>
      </Row>
    );
  };

  @computed
  get statementsContainer(): ReactNode {
    return (
      <Col span={18}>
        {this.activeDateIndex === -1 && (
          <Card title="Habits results overview">
            {this.responseRates}
            <div>
              {this.uiStore?.statements.map((statement, i) => {
                return this.renderOverviewStatement(statement, i);
              })}
              <div className="personal-habit-report-legend d-flex justify-center m-auto">
                <img className="graph-legend" src="/images/pulse-survey-legend.svg" />
              </div>
            </div>
          </Card>
        )}

        {this.activeDateIndex >= 0 && (
          <Card title="Habits results">
            <div>
              {this.uiStore?.statements.map((statement, i) => {
                return this.renderStatement(statement, i);
              })}
            </div>
          </Card>
        )}
      </Col>
    );
  }

  @computed
  get organizationId(): number {
    if (this.ownerType !== OWNER_TYPE_TEAMS) {
      return null;
    }

    return this.uiStore.pulseForTeamStore.pulse.item?.team?.item?.organization_id;
  }

  @computed
  get memberLicenseBanner(): ReactNode {
    if (this.props.isTestDrive || !this.organizationId) {
      return null;
    }

    return <MemberLicenseBanner organizationId={this.organizationId} />;
  }

  public render(): ReactNode {
    if (this.isLoading) {
      return <FakeBox className="w-100" height="12rem" />;
    }

    return (
      <PulseReportContext.Provider value={{ uiStore: this.uiStore }}>
        <div className="personal-habit-report">
          {this.headerNav}
          {this.isLoading && <FakeBox width="100%" height="20rem" className="m-4" />}

          {!this.isLoading && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="container">
                    <div className="personal-habit-report-body">
                      {this.bodyHeader}
                      <Row gutter={24}>
                        {this.datesNavigation}
                        {this.statementsContainer}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </PulseReportContext.Provider>
    );
  }
}

export default inject(
  STORE_PULSE_FOR_MEMBER,
  STORE_PULSE_FOR_TEAM,
  STORE_PERSONAL_PULSE_REPORT
)(withRouter(observer(PulseReport)));
